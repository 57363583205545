<template>
  <div>
    <va-card title="Update Profile">
      <div>
        <form>
          <va-input
            v-model="user_name"
            type="text"
            label="User Name *"
            :disabled='true'
          />
          <va-input
            v-model="password"
            type="password"
            label="Password"
            pattern="[A-Za-z0-9_@!./#&+-^]{8,}$"
            placeholder="Enter Password"
            :error="!!passwordErrors.length"
            :error-messages="passwordErrors"

          />
          <va-input
            v-model="phone_number"
            type="text"
            label="Phone Number *"
            pattern="[6-9]{1}[0-9]{9}"
            maxlength="10"
            placeholder="Enter Phone Number"
            :error="!!phoneNumberErrors.length"
            :error-messages="phoneNumberErrors"
          />
          <div class="d-flex justify--center mt-3">
            <va-button type="submit" class="my-0" @click.prevent="updateUser()">Update</va-button>
          </div>
        </form>
      </div>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
import regex from  '../../i18n/regex_pattern.js'
Vue.use(vueResource)

export default {
  name: 'profile',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + 'user/' + this.$cookies.get('user-id')).then(response => {
      loader.hide()
      var user_profile = response.body
      this.user_name = user_profile.user_name
      this.password = ''
      this.phone_number = user_profile.phone_number
      this.user_id = user_profile.user_id
    }, error => {
      if (error && error.body) {
        Vue.notify({
          text: error.body,
          type: 'error',
        })
      }
      this.getAllUser()
      loader.hide()
    })
  },
  data () {
    return {
      user_name: '',
      password: '',
      phone_number: '',
      userNameErrors: [],
      passwordErrors: [],
      phoneNumberErrors: [],
    }
  },
  computed: {
     checkpassword(){
       if(this.password.length==0){
          return true;
       }else if(this.password.length >= 8){
           return true;
       }else{
          return false;
       }

     },
      formReady () {
      return !this.phoneNumberErrors.length
    },

  },
  methods: {
    getAllUser () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host+'user/' + this.$cookies.get('user-id')).then(response => {
        loader.hide()
        var user_profile = response.body
        this.user_name = user_profile.user_name
        this.password = ''
        this.phone_number = user_profile.phone_number
        this.user_id = user_profile.user_id
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({
            text: error.body,
            type: 'error',
          })
        }
      })
    },

    updateUser () {
      this.roleErrors = this.role ? [] : ['Role is required']
      this.platformTypeErrors = this.platform_type ? [] : ['Platform Type is required']
      this.userNameErrors = this.user_name ? [] : ['User Name is required']
      this.phoneNumberErrors = this.phone_number ? [] : ['Phone Number is required']
      if (!this.checkpassword) {
         Vue.notify({
            text: 'Password Length must 8 Characters',
            type: 'error',
         })
        return
     }
      if (!this.formReady) {
        return
      }
      if (this.passwordFlag && !this.checkPassword) {
        Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
        return
      }
      if (this.phone_numberFlag && !this.checkPhone_number) {
        Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
        return
      }
      if((this.phone_number.length != 10) || !regex.numberRegex(this.phone_number)){
          return Vue.notify({ text: 'Phone Number is not valid!', type: 'error' })
        }

      var payload = {
        user_name: this.user_name,
        last_name: this.last_name,
        phone_number: this.phone_number,
      }
      if (this.password) {
        payload.password = this.password
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host+'user/' + this.user_id, payload).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({
            text: response.body,
            type: 'success',
          })
        }
        this.getAllUser()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({
            text: error.body,
            type: 'error',
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
